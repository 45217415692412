import { default as indexMbo7Y0Jh15Meta } from "/tmp/build_7e128e8f/pages/connections/index.vue?macro=true";
import { default as indexy111LQWF24Meta } from "/tmp/build_7e128e8f/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_939TxdUw5G1KMeta } from "/tmp/build_7e128e8f/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_93cZfg6ugKLzMeta } from "/tmp/build_7e128e8f/pages/gallery/[file_id].vue?macro=true";
import { default as galleryM9LMrS09MbMeta } from "/tmp/build_7e128e8f/pages/gallery.vue?macro=true";
import { default as indexI1tIxCt3UAMeta } from "/tmp/build_7e128e8f/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_93aMEFiTeZocMeta } from "/tmp/build_7e128e8f/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45innPZrOUD6dVMeta } from "/tmp/build_7e128e8f/pages/log-in.vue?macro=true";
import { default as log_45outv1zctPxbQ0Meta } from "/tmp/build_7e128e8f/pages/log-out.vue?macro=true";
import { default as indexR34Lx3rw1iMeta } from "/tmp/build_7e128e8f/pages/notifications/index.vue?macro=true";
import { default as projects8FHaJUImFgMeta } from "/tmp/build_7e128e8f/pages/projects.vue?macro=true";
import { default as _91file_id_93UKvx9gQN1TMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93m7bvhR9A5oMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexKqJA1KXOlPMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visitedv0hqy2oXyBMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_93aRGLxV7z1GMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93uAmy18jCXnMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93zqxTqltn5hMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as index2N3OV5JFT8Meta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_93H1Mqu8auuWMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93Legsq6n5slMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexDq2hbfimVVMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93MTTd1du3uvMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projects54us6Zw8faMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_93cfbiIKqtF8Meta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93R8HCxpMc12Meta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexlJUcUKcxSgMeta } from "/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcome3k583569tZMeta } from "/tmp/build_7e128e8f/pages/welcome.vue?macro=true";
import { default as component_45stub37jyu1dfSrMeta } from "/tmp/build_7e128e8f/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub37jyu1dfSr } from "/tmp/build_7e128e8f/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: indexMbo7Y0Jh15Meta || {},
    component: () => import("/tmp/build_7e128e8f/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexy111LQWF24Meta || {},
    component: () => import("/tmp/build_7e128e8f/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_7e128e8f/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryM9LMrS09MbMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_7e128e8f/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexI1tIxCt3UAMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_7e128e8f/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_7e128e8f/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_7e128e8f/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexR34Lx3rw1iMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projects8FHaJUImFgMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: indexKqJA1KXOlPMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visitedv0hqy2oXyBMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: indexDq2hbfimVVMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projects54us6Zw8faMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexlJUcUKcxSgMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcome3k583569tZMeta || {},
    component: () => import("/tmp/build_7e128e8f/pages/welcome.vue")
  },
  {
    name: component_45stub37jyu1dfSrMeta?.name,
    path: "/",
    component: component_45stub37jyu1dfSr
  },
  {
    name: component_45stub37jyu1dfSrMeta?.name,
    path: "/library",
    component: component_45stub37jyu1dfSr
  },
  {
    name: component_45stub37jyu1dfSrMeta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stub37jyu1dfSr
  }
]